import React, { useEffect, useState } from 'react';
import MyVerticallyCenteredModal from '../modal_styles/modalCentered';
import validator from 'validator';
import axios from 'axios';
import config from '../../../utils/config';
import { FormattedMessage } from 'react-intl';

const passwordRegex = '.{8,}';

function UpdatePasswordModal(props) {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validMessage, setValidMessage] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [validPassword, setValidPassword] = useState(false);
    const [validNewPassword, setValidNewPassword] = useState(false);
    const [validConfirmPassword, setValidConfirmPassword] = useState(false);

    const keyPress = (e) => {
        if (disabledSubmit == true) {
            if (e.which == 13) {
                updatePassword();
            }
        }
    };

    useEffect(() => {
        validFields();
    }, [password, newPassword, confirmPassword]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    const validatePassword = (data) => {
        if (data.match(passwordRegex)) {
            setPassword(data);
            setValidPassword(true);
        } else {
            setPassword(data);
            setValidPassword(false);
        }
    };
    const validateNewPassword = (data) => {
        if (data.match(passwordRegex)) {
            setNewPassword(data);
            setValidNewPassword(true);
        } else {
            setNewPassword(data);
            setValidNewPassword(false);
        }
    };
    const validateConfirmPassword = (data) => {
        if (data === newPassword) {
            setConfirmPassword(data);
            setValidConfirmPassword(true);
        } else {
            setConfirmPassword(data);
            setValidConfirmPassword(false);
        }
    };
    const validFields = () => {
        if (validPassword === true && validNewPassword === true && validConfirmPassword === true) {
            setDisabledSubmit(true);
            setError('');
        } else {
            setValidMessage('');
            setDisabledSubmit(false);
            if (validPassword === false) {
                setError('');
                setError('Kodeord skal være minimum 8 karakterer');
            } else if (validNewPassword === false) {
                setError('');
                setError('Kodeord skal være minimum 8 karakterer');
            } else if (validConfirmPassword === false) {
                setError('');
                setError('Kodeord matcher ikke');
            }
        }
    };

    const updatePassword = () => {
        setError(null);
        setLoading(true);
        axios
            .post(`${config.baseUrl}/customerportal/updatePassword`, {
                currentPassword: password,
                password: newPassword,
                email: props.email,
            })
            .then((response) => {
                setLoading(false);
                setPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setValidPassword(false);
                setValidNewPassword(false);
                setValidConfirmPassword(false);
                setValidMessage(response.data.message);
                setDisabledSubmit(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log('error', error);
                setError(error.response.data.message);
            });
    };

    return (
        <div>
            <MyVerticallyCenteredModal
                className={'no-header update_profile_modal'}
                show={props.show}
                modaltitle={props.title}
                modalcontent={
                    <>
                        <div className="">
                            <div className="input-group gold-border">
                                <label htmlFor="password">
                                    <FormattedMessage id="password" defaultMessage="Password" />
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => {
                                        validatePassword(e.target.value);
                                    }}
                                />
                            </div>

                            <div className="input-group gold-border">
                                <label htmlFor="newPassword">
                                    <FormattedMessage id="newPassword" defaultMessage="New password" />
                                </label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    value={newPassword}
                                    onChange={(e) => {
                                        validateNewPassword(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="input-group gold-border">
                                <label htmlFor="confirmPassword">
                                    <FormattedMessage id="confirmPassword" defaultMessage="Confirm password" />
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        validateConfirmPassword(e.target.value);
                                    }}
                                />
                            </div>

                            <div className="ml-auto text-right">
                                <div
                                    className={disabledSubmit == false ? 'btn btn-black-cust disabled' : 'btn btn-black-cust'}
                                    onClick={updatePassword}
                                >
                                    {loading ? 'Please wait ...' : 'Update'}
                                </div>
                                <div className="validation-messages">
                                    {error && <div className="error ">{error}</div>}
                                    {validMessage && <div className="success">{validMessage}</div>}
                                </div>
                            </div>
                        </div>
                    </>
                }
                onHide={() => props.onPress()}
            />
        </div>
    );
}
export default UpdatePasswordModal;
