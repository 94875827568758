import React, { useState, useEffect } from 'react';
import './style.css';
import logo from '../../assets/img/logo.png';
import Headers from '../../components/header/header';
import LoginForm from '../../components/login_form/loginForm';
function Login() {
    return (
        <main className="min-vh-100 bg-main">
            <Headers />
            <section className="fit-section">
                <div className="container-fluid fit-section d-flex justify-content-center align-items-center">
                    <LoginForm />
                </div>
            </section>
        </main>
    );
}

export default Login;
