import React, { useState } from 'react';
import MyVerticallyCenteredModal from '../modal_styles/modalCentered';

function BookingSuccessModal(props) {
    return (
        <div>
            <MyVerticallyCenteredModal
                className={'no-header booking_modal_success'}
                show={props.show}
                modaltitle={props.title}
                modalcontent={
                    <>
                        <div className="message">{props.message}</div>
                    </>
                }
                onHide={() => props.onPress()}
            />
        </div>
    );
}
export default BookingSuccessModal;
