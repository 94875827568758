import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import './style.css';
import secureimg from '../../assets/img/secure-icon.svg';
import axios from 'axios';

function ResetForm() {
    return (
        <div className="form brd-rds-4 bg-white padding-20 mx-wdt-740px w-100 text-center">
            <div className="mx-wdt-420px m-auto">
                <h3 className="font-weight-bold mg-b-10">Reset your password</h3>
                <div className="img mg-b-10">
                    <img src={secureimg} alt="icon" />
                </div>
                <div className="text mg-b-26">
                    Enter your e-mail address and we'll send you a link to reset your password.
                </div>
                <div className="input-group">
                    <label htmlFor="Password">New Password</label>
                    <input type="password" id="Password" />
                </div>
                <div className="input-group">
                    <label htmlFor="confirmPass">Confirm Password</label>
                    <input type="password" id="confirmPass" />
                </div>
                <div className="d-flex justify-content-between flex-wrap">
                    <div className="btn btn-white-border-cust ml-auto mr-auto">Cancel</div>
                    <div className="btn btn-black-cust ml-auto mr-auto">Save</div>
                </div>
            </div>
        </div>
    );
}

export default ResetForm;
