import React, { useState } from 'react';
import './style.css';
import Headers from '../../components/header/header';
import SignupForm from '../../components/signup_form/signupForm';

function Signup() {
    return (
        <main className="min-vh-100 bg-main">
            <Headers />
            <section className="fit-section">
                <div className="container-fluid fit-section d-flex justify-content-center align-items-center">
                    <SignupForm />
                </div>
            </section>
        </main>
    );
}

export default Signup;
