import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import arrow_right from '../../assets/img/arrow-right-icon.svg';

const meetingTypes = [
    {
        title: 'Indlevering (PANTSAT.DK)',
        time: '15 minuter',
        subtitle: 'Indlevering af PANTSAT.DK-genstand',
        id: 0,
    },
    {
        title: 'Indlevering  2 (PANTSAT.DK)',
        time: '44 minuter',
        subtitle: 'Indlevering af PANTSAT.DK-genstand',
        id: 1,
    },
];

function Step1(props) {
    return (
        <div>
            <div className="title-book-meeting mg-b-40 text-center">Vælg aftaletype</div>
            <div className="d-flex flex-column">
                {meetingTypes.length > 0 &&
                    meetingTypes.map((item, idx) => {
                        return (
                            <div className="card-meeting mg-b-32" onClick={() => props.onPressed(props.id)}>
                                <div className="mr-4">
                                    <div className="d-flex">
                                        <div className="title">{item.title}</div>
                                        <div className="time">{item.time}</div>
                                    </div>
                                    <div className="subtitle">{item.subtitle}</div>
                                </div>

                                <div className="img">
                                    <img src={arrow_right} alt="go" />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps, null)(Step1);
