import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './style.css';
import closeIcon from '../../assets/img/close-black-icon.svg';

function MessageNotification(props) {
    if (props.persistent) {
        return (
            <div className="message-notification d-flex align-items-center justify-content-between w-100">
                <div className="message">{props.message}</div>
            </div>
        );
    }
    return (
        <div className="message-notification d-flex align-items-center justify-content-between w-100">
            <div className="message">{props.message}</div>
            <span className="ml-4" onClick={() => props.onPress(props.id)} data-id={props.id}>
                <img src={closeIcon} alt="close" />
            </span>
        </div>
    );
}

export default MessageNotification;
