import React from 'react';
import Modal from 'react-bootstrap/cjs/Modal';
import closeIcon from '../../assets/img/close_gold.svg';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={props.className}
        >
            <Modal.Header>
                <h4 className="color-gold">{props.modaltitle}</h4>
                <div className="modal-close cursor-pointer">
                    <img src={closeIcon} alt="close" onClick={props.onHide} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="marg-auto p-3">
                    <div>{props.modalcontent}</div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default MyVerticallyCenteredModal;
