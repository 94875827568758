import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';

import English from './compiled-lang/en.json';
import Danish from './compiled-lang/da.json';

const locale = navigator.language;
let messages;
if (locale.indexOf('en') > -1) {
    messages = English;
} else {
    if (locale.indexOf('da') > -1) {
        messages = Danish;
    }
}

ReactDOM.render(
    <IntlProvider locale={locale} messages={messages}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </IntlProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
