import React, { useState } from 'react';
import MyVerticallyCenteredModal from '../modal_styles/modalCentered';

function SuccessModal(props) {
    return (
        <div>
            <MyVerticallyCenteredModal
                className={'no-header'}
                show={props.show}
                modaltitle={props.title}
                modalcontent={
                    <>
                        <div>
                            <h2 className="color-gold text-center">{props.message} </h2>
                            <div className="img text-center mg-b-50">
                                <img src={props.succesIcon} alt="succes-icon" />
                            </div>
                        </div>
                    </>
                }
                onHide={() => props.onPress()}
            />
        </div>
    );
}
export default SuccessModal;
