import React, { useState } from 'react';
import MyVerticallyCenteredModal from '../modal_styles/modalCentered';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import arrow_bottom from '../../assets/img/icon-arrow-bottom.svg';
import BookingSuccessModal from './booking_success_modal';
import Step1 from '../booking_modal_steps/step_1';
import Step2 from '../booking_modal_steps/step_2';
import Step3 from '../booking_modal_steps/step_3';
import Step4 from '../booking_modal_steps/step_4';

function BookMeetingModal(props) {
    const [key, setKey] = useState('1');
    let [toggleModal, setToggleModal] = useState(false);

    let toggleSwitch = (k) => {
        if (k === '1') {
            setKey('1');
        }
        if (k === '2') {
            setKey('2');
        }
        if (k === '3') {
            setKey('3');
        }
        if (k === '4') {
            setKey('4');
        }
        if (k === '5') {
            setKey('5');
        }
    };

    return (
        <div>
            <MyVerticallyCenteredModal
                className={'no-header book-meeting-modal'}
                show={props.show}
                modaltitle={props.title}
                modalcontent={
                    <>
                        <div className="book-meeting-tabs">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k, e) => {
                                    toggleSwitch(k);
                                    setKey(k);
                                }}
                            >
                                <Tab eventKey="1" title="Meeting Types" disabled={false}>
                                    <div className="w-80 m-auto">
                                        <Step1
                                            onPressed={() => {
                                                {
                                                    toggleSwitch('2');
                                                }
                                            }}
                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey="2" title="Afdelinger" disabled={key < 2}>
                                    <div className="w-80 m-auto">
                                        <Step2
                                            onPressed={() => {
                                                {
                                                    toggleSwitch('3');
                                                }
                                            }}
                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey="3" title="Tider" disabled={key < 3}>
                                    <Step3
                                        onPressed={() => {
                                            {
                                                toggleSwitch('4');
                                            }
                                        }}
                                    />
                                </Tab>
                                <Tab eventKey="4" title="Information" disabled={key < 4}>
                                    <Step4
                                        onPressed={() => {
                                            {
                                                toggleSwitch('5');
                                            }
                                        }}
                                    />
                                </Tab>
                                <Tab eventKey="5" title="Bekraeft Booking" disabled={key < 5}>
                                    <div className="title-book-meeting mg-b-10 text-center">Tjek dine oplysninger</div>
                                    <div className="w-75 m-auto">
                                        <div className="mg-b-20">
                                            <div className="d-flex align-items-center justify-content-between ft-sz-15 mg-b-10">
                                                <div>TID</div>
                                                <div>Europe/Copenhagen</div>
                                            </div>
                                            <div className="rediger-content">
                                                <div className="w-80">tirsdag d. 20. april 2021 kl. 14:05 - 14:20</div>
                                                <div
                                                    className="btn btn-black-cust btn-small m-0"
                                                    onClick={(k) => {
                                                        toggleSwitch('3');
                                                    }}
                                                >
                                                    Rediger
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mg-b-20">
                                            <div className="d-flex align-items-center justify-content-between ft-sz-15 mg-b-10">
                                                <div>MEETING TYPE</div>
                                            </div>
                                            <div className="rediger-content">
                                                <div className="w-80">
                                                    <b>Indlevering (PANTSAT.DK)</b> 15 minutter
                                                </div>
                                                <div
                                                    className="btn btn-black-cust btn-small m-0"
                                                    onClick={(k) => {
                                                        toggleSwitch('1');
                                                    }}
                                                >
                                                    Rediger
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mg-b-20">
                                            <div className="d-flex align-items-center justify-content-between ft-sz-15 mg-b-10">
                                                <div>AFDELING</div>
                                            </div>
                                            <div className="rediger-content">
                                                <div className="w-80">
                                                    <b>København - H.C. Andersens Boulevard 38, st. tv., 1553 København V</b>
                                                </div>
                                                <div
                                                    className="btn btn-black-cust btn-small m-0"
                                                    onClick={(k) => {
                                                        toggleSwitch('2');
                                                    }}
                                                >
                                                    Rediger
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mg-b-20">
                                            <div className="d-flex align-items-center justify-content-between ft-sz-15 mg-b-10">
                                                <div>DINE OPLYSNINGER</div>
                                            </div>

                                            <div className="w-max-content">
                                                <div className="d-flex flex-wrap align-items-center justify-content-end mg-b-10">
                                                    <div className="text-right mr-2 ft-sz-13">E-Mail</div>
                                                    <div className="rediger-content ">
                                                        <div className="w-80 mr-4">
                                                            <b>demo@demo.dk</b>
                                                        </div>
                                                        <div
                                                            className="btn btn-black-cust btn-small m-0"
                                                            onClick={(k) => {
                                                                toggleSwitch('4');
                                                            }}
                                                        >
                                                            Rediger
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap align-items-center justify-content-end">
                                                    <div className="text-right mr-2 ft-sz-13">Telefonnummer</div>
                                                    <div className="rediger-content">
                                                        <div className="w-80 mr-4">
                                                            <b>7888888</b>
                                                        </div>
                                                        <div
                                                            className="btn btn-black-cust btn-small m-0"
                                                            onClick={(k) => {
                                                                toggleSwitch('4');
                                                            }}
                                                        >
                                                            Rediger
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-75 text-center m-auto">
                                            <div className="btn btn-black-cust  mg-b-20 " onClick={() => setToggleModal(!toggleModal)}>
                                                Bekraeft booking
                                                <img src={arrow_bottom} className="ml-3" alt="arrow" />
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </>
                }
                onHide={() => props.onPress()}
            />
            <BookingSuccessModal
                title={'Din booking er gennemført'}
                message={'Vi glæder os til at se dig i butikken på det aftalte tidspunkt.'}
                onPress={setToggleModal}
                show={toggleModal}
            />
        </div>
    );
}
export default BookMeetingModal;
