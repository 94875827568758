import React, { useState } from 'react';
import MyVerticallyCenteredModal from '../modal_styles/modalCentered';
import GetContactModal from './get_contact_modal';
import { Card, Accordion, Button, useAccordionToggle } from 'react-bootstrap';
import axios from 'axios';
import config from '../../../utils/config';

function AdministerModal(props) {
    let [toggleModal4, setToggleModal4] = useState(false);
    const [validMessage, setValidMessage] = useState('');
    const [error, setError] = useState('');
    const [openedAccordeon, setOpenedAccordeon] = useState(0);
    const handleBS = () => {
        axios
            .post(`${config.baseUrl}/customerportal/recurring`, {
                contractId: props.contractId,
                paymentMethod: 'BS',
            })
            .then((response) => {
                setError('');
                setValidMessage(response.data.message);
            })
            .catch((error) => {
                setValidMessage('');
                setError(error.response.data.message);
            });
    };

    function CustomToggle({ children, eventKey }) {
        const activeClass = useAccordionToggle(eventKey, () => {
            if (openedAccordeon == eventKey) {
                setOpenedAccordeon(null);
            } else {
                setOpenedAccordeon(eventKey);
            }
        });

        return (
            <div className={openedAccordeon == eventKey ? 'card-header opened' : 'card-header'} onClick={activeClass}>
                {children}
            </div>
        );
    }

    const handleReppayRecuring = () => {
        axios
            .post(`${config.baseUrl}/customerportal/recurring`, {
                contractId: props.contractId,
                paymentMethod: 'REEPAY_RECURRING',
            })
            .then((response) => {
                setError('');
                setValidMessage(response.data.message);
            })
            .catch((error) => {
                console.log('error', error);
                setValidMessage('');
                setError(error.response.data.message);
            });
    };
    return (
        <div>
            <MyVerticallyCenteredModal
                className={'no-header administer_modal'}
                show={props.show}
                modaltitle={props.title}
                modalcontent={
                    <>
                        {/* <div>
                            <div className="d-flex flex-column align-items-center justify-content-center max-475 m-auto">
                                <div className="btn-gold-border-cust mg-b-26 w-100">Prolong your contract</div>
                                <div className="btn-gold-border-cust mg-b-26 w-100">Buy back your item</div>
                                <div className="btn-gold-border-cust mg-b-26 w-100">Installment plan</div>
                                <div className="btn-gold-border-cust mg-b-26 w-100" onClick={()=>setToggleModal4(!toggleModal4)}>Get your contract</div>
                                <div className="btn-gold-border-cust mg-b-26 w-100">Sign up for monthly payments</div>
                            </div>
                        </div> */}
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <CustomToggle eventKey="0">Prolong your contract</CustomToggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>Hello! I'm the body</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <CustomToggle eventKey="1">Buy back your item</CustomToggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>Hello! I'm another body</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <CustomToggle eventKey="2">Installment plan</CustomToggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>Hello! I'm another body</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <CustomToggle eventKey="3">Get your contract</CustomToggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>Hello! I'm another body</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <CustomToggle eventKey="4">Sign up for monthly payments</CustomToggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <p>
                                            When you sign up for monthly payments, you can sign up with Betalingsservice or recurring payments with
                                            creditcard.
                                        </p>
                                        <div className="btn btn-black-cust" onClick={handleBS}>
                                            Betalingsservice
                                        </div>
                                        <div className="btn btn-black-cust" onClick={handleReppayRecuring}>
                                            creditcard
                                        </div>
                                        {/*<p className="small text-success">We will try to sign you up to BS</p>*/}
                                        {error && <div className="error small">{error}</div>}
                                        {validMessage && <div className="small text-success">{validMessage}</div>}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </>
                }
                onHide={() => props.onPress()}
            />
            <GetContactModal title={'Get your contract'} onPress={setToggleModal4} show={toggleModal4} />
        </div>
    );
}

export default AdministerModal;
