import React, { useEffect, useState } from 'react';
import MyVerticallyCenteredModal from '../modal_styles/modalCentered';
import validator from 'validator';
import axios from 'axios';
import config from '../../../utils/config';
import { FormattedMessage } from 'react-intl';

function UpdateProfileModal(props) {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [validMessage, setValidMessage] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validPhone, setValidPhone] = useState(true);

    const keyPress = (e) => {
        if (disabledSubmit == true) {
            if (e.which == 13) {
                updateContactDetails();
            }
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        validFields();
    }, [email, phone]);

    const validateEmail = (data) => {
        if (validator.isEmail(data)) {
            setEmail(data);
            setDisabledSubmit(true);
            setValidEmail(true);
        } else {
            setEmail(data);
            setValidEmail(false);
        }
    };
    const validatePhone = (data) => {
        if (data.length > 5 && validator.isInt(data)) {
            setPhone(data);
            setDisabledSubmit(true);
            setValidPhone(true);
        } else {
            setPhone(data);
            setValidPhone(false);
        }
    };
    const validFields = () => {
        if (validEmail === true && validPhone === true) {
            setDisabledSubmit(true);
            setError('');
        } else {
            setValidMessage('');
            setDisabledSubmit(false);
            if (validEmail === false) {
                setError('');
                setError('Enter valid Email!');
            }
            if (validPhone === false) {
                setError('');
                setError('Enter valid Phone!');
            }
        }
    };
    useEffect(() => {
        setEmail(props.currentEmail);
        setPhone(props.currentPhone);
    }, []);

    const updateContactDetails = () => {
        setError(null);
        setLoading(true);
        axios
            .post(`${config.baseUrl}/customerportal/updateContactDetails`, {
                email: email,
                phone: phone,
            })
            .then((response) => {
                setLoading(false);
                setValidMessage(response.data.message);
            })
            .catch((error) => {
                setLoading(false);
                console.log('error', error);
                // setError(error.response.data.message)
            });
    };

    return (
        <div>
            <MyVerticallyCenteredModal
                className={'no-header update_profile_modal'}
                show={props.show}
                modaltitle={props.title}
                modalcontent={
                    <>
                        <div className="two-flex">
                            {/*<div className="input-group gold-border">*/}
                            {/*    <label htmlFor="name">Name</label>*/}
                            {/*    <input type="text" id="name"/>*/}
                            {/*</div>*/}
                            <div className="input-group gold-border">
                                <label htmlFor="email">email</label>
                                <input
                                    type="text"
                                    id="email"
                                    value={email}
                                    onChange={(e) => {
                                        validateEmail(e.target.value);
                                    }}
                                />
                            </div>
                            {/*<div className="input-group gold-border">*/}
                            {/*    <label htmlFor="surname">Surname</label>*/}
                            {/*    <input type="text" id="surname"/>*/}
                            {/*</div>*/}
                            <div className="input-group gold-border">
                                <label htmlFor="phone">
                                    <FormattedMessage id="phone" defaultMessage="Phone number" />
                                </label>
                                <input
                                    type="tel"
                                    id="phone"
                                    value={phone}
                                    onChange={(e) => {
                                        validatePhone(e.target.value);
                                    }}
                                />
                            </div>
                            {/*<div className="input-group gold-border">*/}
                            {/*    <label htmlFor="address">Address</label>*/}
                            {/*    <input type="text" id="address"/>*/}
                            {/*</div>*/}
                            {/*<div className="input-group gold-border">*/}
                            {/*    <label htmlFor="zip">Zip code</label>*/}
                            {/*    <input type="text" id="zip"/>*/}
                            {/*</div>*/}
                            <div className="ml-auto text-right">
                                <div
                                    className={disabledSubmit == false ? 'btn btn-black-cust disabled' : 'btn btn-black-cust'}
                                    onClick={updateContactDetails}
                                >
                                    {loading ? 'Please wait ...' : 'Update'}
                                </div>
                                <div className="validation-messages">
                                    {error && <div className="error ">{error}</div>}
                                    {validMessage && <div className="success">{validMessage}</div>}
                                </div>
                            </div>
                        </div>
                    </>
                }
                onHide={() => props.onPress()}
            />
        </div>
    );
}
export default UpdateProfileModal;
