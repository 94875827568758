import React, { useEffect, useState } from 'react';
import MyVerticallyCenteredModal from '../modal_styles/modalCentered';
import axios from 'axios';
import config from '../../../utils/config';

function GetContactModal(props) {
    return (
        <div>
            <MyVerticallyCenteredModal
                className={'no-header get_contact_modal'}
                show={props.show}
                modaltitle={props.title}
                modalcontent={
                    <>
                        <div>
                            <div className="d-flex align-items-center justify-content-center m-auto mx-wdt-250px">
                                <a href="" download className="btn-black-cust mg-b-26 w-100">
                                    Download
                                </a>
                            </div>
                        </div>
                    </>
                }
                onHide={() => props.onPress()}
            />
        </div>
    );
}
export default GetContactModal;
