import React, { useState } from 'react';
import './style.css';
import logo from '../../assets/img/logo.png';
import Headers from '../../components/header/header';
import ForgotForm from '../../components/forgot_form/forgotForm';

function Forgot() {
    return (
        <main className="min-vh-100 bg-main">
            <Headers />
            <section className="fit-section">
                <div className="container-fluid fit-section d-flex justify-content-center align-items-center">
                    <ForgotForm />
                </div>
            </section>
        </main>
    );
}

export default Forgot;
