import React, { useState } from 'react';
import MyVerticallyCenteredModal from '../modal_styles/modalCentered';
import axios from 'axios';
import config from '../../../utils/config';
import { formatDKK } from '../../../utils/common';
import { FormattedMessage } from 'react-intl';

function RecurringSignupModal(props) {
    const [validMessage, setValidMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const requestRecurringHandler = (paymentMethod) => {
        setLoading(true);
        axios
            .post(`${config.baseUrl}/customerportal/recurring`, {
                contractId: props.contractId,
                paymentMethod: paymentMethod,
            })
            .then((response) => {
                let message = response.data.message;
                if (response.data.redirect) {
                    window.location.href = response.data.redirect;
                } else if (response.data.obj.signUrl) {
                    message = `Din aftale skal underskrives på betalingsservice, inden den træder i kraft. Du viderestilles nu til betalingsservice.`;
                    alert(message);
                    window.location.href = response.data.obj.signUrl;
                }
                setValidMessage(message);
                setLoading(false);
            })
            .catch((error) => {
                console.log('error requestPayment', error);
                setError(error.response.data.message);
                setLoading(false);
            });
    };

    const getMissingInformation = (props) => {
        const out = [];
        const keys = Object.keys(props);
        keys.forEach((key) => {
            const val = props[key];
            if (!val) out.push(key);
        });
        return out;
    };

    return (
        <div>
            <MyVerticallyCenteredModal
                className={'no-header recurring_signup'}
                show={props.show}
                modaltitle={props.title}
                modalcontent={
                    <>
                        <div>
                            <div className="subtitle text-left ft-sz-23 w-80 ml-auto mr-auto mg-b-50">
                                <FormattedMessage
                                    id="signupmodal.explanation"
                                    defaultMessage="By signing up to recurring payments, you'll make sure that you automatically pay your monthly premium and that your contract never expires."
                                />
                            </div>
                            <div className="two-grid">
                                <div class="d-flex flex-column">
                                    <div className="title-bordered mg-b-50 mx-auto">Betalingsservice</div>
                                    <div className="mb-3">
                                        <div className="d-flex font-weight-bold bold-family main-color mg-b-10 align-items-start">
                                            <span className="ft-sz-20 main-color font-weight-bold bold-family mr-auto">
                                                <FormattedMessage id="amount" defaultMessage="Amount" />
                                            </span>
                                            <span className="ft-sz-20 color-gold font-weight-bold bold-family">
                                                {formatDKK(props.monthlyPayment)}
                                            </span>
                                        </div>
                                        <div className="d-flex font-weight-bold bold-family main-color mg-b-10 align-items-start">
                                            <span className="ft-sz-20 main-color font-weight-bold bold-family mr-auto">
                                                <FormattedMessage id="yourBank" defaultMessage="Your bank account" />
                                            </span>
                                            <span className="ft-sz-20 color-gold font-weight-bold bold-family">
                                                {props.registrationNumber}-{props.accountNumber}
                                            </span>
                                        </div>
                                        <div className="d-flex font-weight-bold bold-family main-color align-items-start">
                                            <span className="ft-sz-20 main-color font-weight-bold bold-family mr-auto">CPR:</span>
                                            <span className="ft-sz-20 color-gold font-weight-bold bold-family">{props.cpr}</span>
                                        </div>
                                    </div>
                                    <div
                                        className={`align-self-center mt-auto d-flex flex-column ${
                                            getMissingInformation(props).length ? 'd-none' : ''
                                        }`}
                                    >
                                        <div className={'mb-3'}>
                                            <FormattedMessage
                                                id="signup.incorrectInfo"
                                                defaultMessage="if any of the informations above are incorrect and you still want to signup, please call us or write us"
                                            />
                                        </div>
                                        <div className="btn btn-black-cust align-self-center" onClick={() => requestRecurringHandler('BS')}>
                                            {loading ? (
                                                <FormattedMessage id="pleaseWait" defaultMessage="Please wait..." />
                                            ) : (
                                                <FormattedMessage id="signup.withbs" defaultMessage="Sign up with BS" />
                                            )}
                                        </div>
                                    </div>
                                    <div className={`mb-3 ${getMissingInformation(props).length > 0 ? '' : 'd-none'}`}>
                                        <FormattedMessage
                                            id="signup.missingFollowingInformation"
                                            defaultMessage="We are missing the following information"
                                        />
                                        :
                                        <br />
                                        {getMissingInformation(props).join('<br/>')}
                                        <br />
                                        <br />
                                        <FormattedMessage
                                            id="signup.pleaseContactUsForBs"
                                            defaultMessage="Please contact us to sign up for Betalingsservice"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="title-bordered mg-b-50 mx-auto">
                                        <FormattedMessage id="creditCard" defaultMessage="Credit card" />
                                    </div>
                                    <div className="ft-sz-20 text-left mb-3">
                                        <FormattedMessage
                                            id="recurringCardPayment"
                                            defaultMessage="You will be redirected to a page where you can add your card information"
                                        />
                                    </div>
                                    <div
                                        className="btn btn-black-cust align-self-center mt-auto"
                                        onClick={() => requestRecurringHandler('REEPAY_RECURRING')}
                                    >
                                        {loading ? (
                                            <FormattedMessage id="pleaseWait" defaultMessage="Please wait..." />
                                        ) : (
                                            <FormattedMessage id="signup.withcreditcard" defaultMessage="Sign up with credit card" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {error && <div className="error text-center mt-3">{error}</div>}
                            {validMessage && <div className="success text-center mt-3">{validMessage}</div>}
                        </div>
                    </>
                }
                onHide={() => {
                    props.onPress();
                    setValidMessage('');
                    setError('');
                }}
            />
        </div>
    );
}
export default RecurringSignupModal;
