import React, { useState } from 'react';
import MyVerticallyCenteredModal from '../modal_styles/modalCentered';
import GetContactModal from './get_contact_modal';
import { Link } from 'react-router-dom';
import BuybackYourPriceModal from './buyback_your_price_modal';
import axios from 'axios';
import config from '../../../utils/config';
import { formatDKK } from '../../../utils/common';
import { FormattedMessage } from 'react-intl';

const getSubtitleField = (props) => {
    if (props.notOnRecurring && props.onRecurring) {
        return (
            <div className="subtitle text-center ft-sz-23 w-80 ml-auto mr-auto mg-b-50">
                Right now {props.onRecurring} our of your {props.onRecurring + props.notOnRecurring} contracts are signed up for recurring payments.
                If you want to prolong the missing {props.notOnRecurring}, please transfer {props.monthlyPayment}
            </div>
        );
    } else {
        <div className="subtitle text-left ft-sz-23 w-80 ml-auto mr-auto mg-b-50">
            <FormattedMessage
                id="buybackmodal.explanation"
                defaultMessage="You have to prolong your contracts each month to maintain your contract."
            />
        </div>;
    }
};

function ProlongContractModal(props) {
    const [validMessage, setValidMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const requestPaymentHandler = () => {
        setLoading(true);
        axios
            .post(`${config.baseUrl}/customerportal/requestPayment`, {
                amount: props.cardAmount,
                contractId: props.contractId,
            })
            .then((response) => {
                if (response.data.redirect) {
                    window.location.href = response.data.redirect;
                    setLoading(false);
                    
                } else {
                    setLoading(false);
                    setValidMessage(response.data.message);
                }
            })
            .catch((error) => {
                setError(error.response.data.message);
                setLoading(false);
            });
    };

    return (
        <div>
            <MyVerticallyCenteredModal
                className={'no-header prolong_contract'}
                show={props.show}
                modaltitle={props.title}
                modalcontent={
                    <>
                        <div>
                            {getSubtitleField(props)}
                            <div className="two-grid">
                                <div>
                                    <div className="title-bordered mg-b-50 mx-auto">
                                        <FormattedMessage id="bankTransfer" defaultMessage="Bank transfer" />
                                    </div>
                                    <div className="mg-b-50">
                                        <div className="d-flex font-weight-bold bold-family main-color mg-b-10 align-items-start">
                                            <span className="ft-sz-20 main-color font-weight-bold bold-family mr-auto">
                                                <FormattedMessage id="amount" defaultMessage="Amount" />:
                                            </span>
                                            <span className="ft-sz-20 color-gold font-weight-bold bold-family">{props.monthlyPayment}</span>
                                        </div>
                                        <div className="d-flex font-weight-bold bold-family main-color mg-b-10 align-items-start">
                                            <span className="ft-sz-20 main-color font-weight-bold bold-family mr-auto">Bank: </span>
                                            <span className="ft-sz-20 color-gold font-weight-bold bold-family">Sparekassen Kronjylland</span>
                                        </div>
                                        <div className="d-flex font-weight-bold bold-family main-color align-items-start">
                                            <span className="ft-sz-20 main-color font-weight-bold bold-family mr-auto">
                                                <FormattedMessage id="bankAccount" defaultMessage="Bank account" />:
                                            </span>
                                            <span className="ft-sz-20 color-gold font-weight-bold bold-family">6680-0020163704</span>
                                        </div>
                                    </div>
                                    <div className="ft-sz-20">
                                        <FormattedMessage
                                            id="rememberImmediateTransfer"
                                            defaultMessage="Remember to make the transfer as a immediate transfer and attach your"
                                        />
                                        {props.contractId[0] === 'K' ? (
                                            <FormattedMessage id="customerNumber" defaultMessage="customer number" />
                                        ) : (
                                            <FormattedMessage id="contractNumber" defaultMessage="Contract number" />
                                        )}{' '}
                                        <span className="ft-sz-20 color-gold font-weight-bold bold-family">{props.contractId} </span>
                                    </div>
                                </div>
                                <div className="text-center d-flex flex-column">
                                    <div className="title-bordered mg-b-50 mx-auto">
                                        <FormattedMessage id="payByCard" defaultMessage="Pay by card" />
                                    </div>
                                    <div className="ft-sz-20 text-left mb-3">
                                        <FormattedMessage
                                            id="cardPaymentFeeOneOff"
                                            defaultMessage="There is a 2% billing fee on card payments, so the payment will be "
                                        />{' '}
                                        {formatDKK(props.cardAmount)}
                                    </div>
                                    <div className="btn btn-black-cust mt-auto align-self-center" onClick={requestPaymentHandler}>
                                        {loading ? (
                                            <FormattedMessage id="pleaseWait" defaultMessage="Please wait..." />
                                        ) : (
                                            <FormattedMessage id="payByCard" defaultMessage="Pay by card" />
                                        )}
                                    </div>
                                    {error && <div className="error ">{error}</div>}
                                    {validMessage && <div className="success">{validMessage}</div>}
                                </div>
                            </div>
                        </div>
                    </>
                }
                onHide={() => {
                    props.onPress();
                    setValidMessage('');
                    setError('');
                }}
            />
        </div>
    );
}
export default ProlongContractModal;
