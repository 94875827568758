import React, { useState } from 'react';
import MyVerticallyCenteredModal from '../modal_styles/modalCentered';

function MessageNotificationModal(props) {
    return (
        <div>
            <MyVerticallyCenteredModal
                className={'no-header message_notification_modal'}
                show={props.show}
                modaltitle={props.title}
                modalcontent={
                    <>
                        <div>
                            <div className="message-text">{props.message} </div>
                        </div>
                    </>
                }
                onHide={() => props.onPress()}
            />
        </div>
    );
}
export default MessageNotificationModal;
