import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import './style.css';
import secureimg from '../../assets/img/secure-icon.svg';
import axios from 'axios';
import validator from 'validator';
import config from '../../../utils/config';
import { FormattedMessage } from 'react-intl';

function ForgotForm() {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disabledEmailBtn, setDisabledEmailBtn] = useState(false);

    const validateEmail = (e) => {
        var email = e.target.value;
        if (!validator.isEmail(email)) {
            setError('Enter valid Email!');
            setDisabledEmailBtn(false);
            setSuccess('');
        } else {
            setDisabledEmailBtn(true);
            setError('');
            setSuccess('');
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const obj = Object.fromEntries(formData);
        setError(null);
        setLoading(true);

        axios
            .post(`${config.baseUrl}/customerportal/signup`, obj)
            .then((response) => {
                setLoading(false);
                setSuccess(response.data.message);
                setEmail('');
                if (response.data.redirect) {
                    history.push(response.data.redirect);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log('error', error);
                setError(error.response?.data?.message || 'Noget gik galt');
            });
    };
    return (
        <div className="form brd-rds-4 bg-white padding-20 mx-wdt-740px w-100 text-center">
            <form id="signupForm" class="text-start mb-3" onSubmit={handleSubmit}>
                <div className="mx-wdt-420px m-auto">
                    <h3 className="font-weight-bold mg-b-10">
                        <FormattedMessage id="signup" defaultMessage="Sign up" />
                    </h3>
                    <div className="img mg-b-10">
                        <img src={secureimg} alt="icon" />
                    </div>
                    <div className="text mg-b-26">
                        <FormattedMessage
                            id="signup.description"
                            defaultMessage="Enter your e-mail address and a password to sign up. Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="Email">Email</label>
                        <input
                            type="email"
                            id="Email"
                            name="email"
                            required
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                validateEmail(e);
                            }}
                        />

                        <label className="mt-3" for="password">
                            <FormattedMessage id="password" defaultMessage="Password" />
                        </label>
                        <input
                            className="form-control"
                            type="password"
                            id="password"
                            name="password"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                            required
                        />
                        <label className="mt-3" for="passwordRepeat">
                            <FormattedMessage id="repeatPassword" defaultMessage="Repeat password" />
                        </label>
                        <input
                            class="form-control"
                            type="password"
                            id="passwordRepeat"
                            name="passwordRepeat"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                            required
                        />
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                        <Link to="/" className="btn btn-white-border-cust ml-auto mr-auto">
                            {success ? (
                                <FormattedMessage id="forgot.gotosignin" defaultMessage="Go to sign in" />
                            ) : (
                                <FormattedMessage id="cancel" defaultMessage="Cancel" />
                            )}
                        </Link>

                        {disabledEmailBtn ? (
                            <button className="btn btn-black-cust ml-auto mr-auto" type="submit">
                                {loading ? (
                                    <FormattedMessage id="forgot.loading" defaultMessage="Loading..." />
                                ) : (
                                    <FormattedMessage id="signup" defaultMessage="Sign up" />
                                )}
                            </button>
                        ) : null}
                    </div>
                    <div className="validation-messages">
                        {error && <div className="error ">{error}</div>}
                        {success && <div className="success ">{success}</div>}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ForgotForm;
