import './App.css';
import 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './app/css/style.css';

import Login from './app/containers/login/Login';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Forgot from './app/containers/forgot/Forgot';
import Signup from './app/containers/signup/Signup';
import Reset from './app/containers/reset/Reset';
import Contracts from './app/containers/contracts/Contracts';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/forgot" component={Forgot} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/reset" component={Reset} />
                <Route exact path="/contracts" component={Contracts} />
            </Switch>
        </Router>
    );
}

export default App;
